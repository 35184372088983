<template>
  <common-trs-page-container :padding="0">
    <div class="d-flex">
      <div>
        <common-trs-card class="mt-n4 pb-0 mb-2">
          <common-trs-page-nav-vertical
            v-model="currentTab"
          >
            <v-tab
              key="shares"
            >
              Shares
            </v-tab>
            <v-tab
              key="warrants"
            >
              Warrants
            </v-tab>
            <v-tab
              key="convertibles"
            >
              Convertibles
            </v-tab>
            <v-tab
              key="options"
            >
              Options
            </v-tab>
          </common-trs-page-nav-vertical>
        </common-trs-card>
      </div>
      <div class="flex-fill mx-6 mx-md-8 overflow-auto">
        <v-tabs-items
          v-model="currentTab"
          class="transparent-bg"
        >
          <v-tab-item
            key="shares"
            :eager="true"
          >
            <shares-tab
              :all-shares="issueableSharesData"
              :loading="loading"
              :holders-list="issuerProfileList"
              :share-terms-list="shareTermsList"
              :eip-list="eipList"
              :financing-round-list="financingRoundList"
              :show-draft="true"
              :hide-fields="[]"
              :clear-filters="clearFilters"
              @stopLoading="stopLoading"
              @reload="reloadHandler('shared')"
              @issueShares="issueSelectedShares"
              @delete="deleteSecurity"
              @issue="issueSelectedSecurity"
            />
          </v-tab-item>
          <v-tab-item
            key="warrants"
            :eager="true"
          >
            <warrants-tab
              :issueable-warrants="issueableWarrants"
              :warrant-terms="warrantTermList"
              :share-terms-list="shareTermsList"
              :financing-rounds="financingRoundList"
              :eips="eipList"
              :investors="issuerProfileList"
              :loading="loading"
              :hide-fields="[]"
              :show-draft="true"
              :clear-filters="clearFilters"
              @stopLoading="stopLoading"
              @issueWarrant="issueSelectedWarrants"
              @reload="reloadHandler('warrant')"
              @delete="deleteSecurity"
              @issue="issueSelectedSecurity"
            />
          </v-tab-item>
          <v-tab-item
            key="convertibles"
            :eager="true"
          >
            <convertibles-tab
              :issueable-convertibles="issueableConvertibles"
              :convertible-terms="convertibleTermList"
              :share-terms-list="shareTermsList"
              :financing-rounds="financingRoundList"
              :eips="eipList"
              :investors="issuerProfileList"
              :loading="loading"
              :show-draft="true"
              :hide-fields="[]"
              :clear-filters="clearFilters"
              @stopLoading="stopLoading"
              @issueConvertible="issueSelectedConvertibles"
              @delete="deleteSecurity"
              @reload="reloadHandler('convertible')"
              @issue="issueSelectedSecurity"
            />
          </v-tab-item>
          <v-tab-item
            key="options"
            :eager="true"
          >
            <options-tab
              :options-data="issueableOptionData"
              :loading="loading"
              :financing-rounds="financingRoundList"
              :share-terms-list="shareTermsList"
              :eips="eipList"
              :investors="issuerProfileList"
              :show-draft="true"
              :hide-fields="[]"
              :clear-filters="clearFilters"
              @stopLoading="stopLoading"
              @issueOption="issueSelectedOptions"
              @delete="deleteSecurity"
              @reload="reloadHandler('options')"
              @issue="issueSelectedSecurity"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </common-trs-page-container>
</template>
<script>
  import WarrantsTab from './../components/tabs/WarrantsTab'
  import SharesTab from './../components/tabs/SharesTab'
  import OptionsTab from './../components/tabs/OptionsTab'
  import ConvertiblesTab from './../components/tabs/ConvertiblesTab'

  import * as captableService from '@/components/common/captable/services/captable'
  import * as captableAdminService from '@/services/captable-admin-service'

  export default {
    name: 'DraftSecuritiesIndex',

    components: {
      SharesTab,
      WarrantsTab,
      OptionsTab,
      ConvertiblesTab,
    },

    data: () => ({
      loading: false,
      currentTab: 0,
      issuerProfileList: [],
      financingRoundList: [],
      eipList: [],
      shareTermsList: [],
      warrantTermList: [],
      issueableSharesData: [],
      convertibleTermList: [],
      issueableWarrants: [],
      issueableOptionData: [],
      issueableConvertibles: [],
      clearFilters: false,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      source () {
        return this.$store.getters['auth/source']
      },
    },

    watch: {
      currentTab: {
        handler (val) {
          if (val === 1 && (!this.issueableWarrants || this.issueableWarrants.length === 0)) {
            this.loadDraftedWarrants()
          } else if (val === 2 && (!this.issueableConvertibles || this.issueableConvertibles.length === 0)) {
            this.loadDraftedConvertibles()
          } else if (val === 3 && (!this.issueableOptionData || this.issueableOptionData.length === 0)) {
            this.loadDraftedOptions()
          }
          this.clearFilters = true
          setTimeout(() => {
            this.clearFilters = false
          }, 100)
        },
        immediate: true,
      },
    },

    async mounted () {
      this.currentTab = this.$route.query.tab ? parseInt(this.$route.query.tab) : 0
      this.loading = true
      await this.loadFinancingRoundList()
      await this.loadShareTermList()
      await this.loadEipList()
      await this.loadIssuerProfileList()
      await this.loadAllConvertibleTerms()
      await this.loadAllWarrantTerms()
      await this.loadDraftedShareList()
    },

    methods: {
      async loadData () {
        this.loading = true
        if (this.currentTab === 0) {
          await this.loadDraftedShareList()
        } else if (this.currentTab === 1) {
          await this.loadDraftedWarrants()
        } else if (this.currentTab === 2) {
          await this.loadDraftedConvertibles()
        } else if (this.currentTab === 3) {
          await this.loadDraftedOptions()
        }
      },

      async loadDraftedShareList () {
        try {
          const resp = await captableService.getIssuedShareList(this.captableId, false)
          this.issueableSharesData = resp.data.shares
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },

      async loadDraftedWarrants () {
        try {
          const resp = await captableService.getIssuedWarrantList(this.captableId, false)
          if (resp && resp.data) {
            this.issueableWarrants = resp.data.warrants || []
            this.loadAllWarrantTerms()
          }
        } catch (err) {
          this.loading = false
        }
      },

      async loadDraftedOptions () {
        try {
          const resp = await captableService.getOptionsList(this.captableId, false)
          this.issueableOptionData = resp.data.options
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },
      async loadDraftedConvertibles () {
        try {
          const resp = await captableService.getIssuedConvertibleList(this.captableId, false)
          if (resp && resp.data) {
            this.issueableConvertibles = resp.data.convertibles || []
          }
        } catch (err) {
          this.loading = false
        }
      },

      async loadShareTermList () {
        try {
          const resp = await captableService.getShareTermList(this.captableId)
          this.shareTermsList = resp.data.share_terms
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },

      async loadAllWarrantTerms () {
        const resp = await captableService.getWarrantTermList(this.captableId)
        if (resp && resp.data) {
          this.warrantTermList = resp.data.warrant_terms || []
        }
      },

      async loadAllConvertibleTerms () {
        const resp = await captableService.getConvertibleTermList(this.captableId)
        if (resp && resp.data) {
          this.convertibleTermList = resp.data.convertible_terms || []
        }
      },

      async loadIssuerProfileList () {
        try {
          const resp = await captableService.getIssuerProfileList(this.captableId)
          this.issuerProfileList = resp.data.profiles || []
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },

      async loadFinancingRoundList () {
        try {
          const resp = await captableService.getAllFinancingRounds(this.captableId)
          if (resp && resp.data) {
            this.financingRoundList = resp.data.financing_rounds || []
          }
        } catch (e) {}
      },

      async loadEipList () {
        try {
          const resp = await captableService.getEipList(this.captableId)
          if (resp && resp.data) {
            this.eipList = resp.data.equity_incentive_plans || []
          }
        } catch (e) {}
      },

      issueSelectedShares (list) {
        this.issueSelectedSecurities(list, this.shareTermsList, 'share_terms_id', 'issuedShareId')
      },

      issueSelectedWarrants (list) {
        this.issueSelectedSecurities(list, this.warrantTermList, 'warrant_terms_id', 'issuedWarrantId', this.CONSTS.SHARE_TERM_TYPES.WARRANT)
      },

      issueSelectedConvertibles (list) {
        this.issueSelectedSecurities(list, this.convertibleTermList, 'convertible_terms_id', 'issuedConvertibleId', this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE)
      },

      async issueSelectedOptions (list) {
        list.forEach((item) => {
          item.type = item.type || item.optionType || this.CONSTS.OPTIONS_TYPE.ISO
        })
        await this.issueSelectedSecurities(list, this.eipList, 'equity_incentive_plan_id', 'issuedOptionId', this.CONSTS.SHARE_TERM_TYPES.OPTION)
      },

      async issueSelectedSecurities (list, securityTermList, termIdKey, securityIdKey, securityTermType) {
        let i = 1
        this.loading = true
        try {
          list.forEach((security) => {
            if (security.is_valid) {
              const securityTerm = securityTermList.find((s) => { return s.id === security[termIdKey] })
              if (securityTermType) {
                securityTerm.type = securityTermType
              }
              const shareTermId = security.share_terms_id
              this.issueSecurity(security, security[securityIdKey], i, list.length, securityTerm, shareTermId)
            }
            i++
          })
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to issue security. Please try again later.',
          })
        }
      },

      async issueSecurity (security, securityId, i, len, securityTerm, shareTermId) {
        try {
          this.loading = true
          await this.issueSecurityByType(securityId, securityTerm)
          if (!securityTerm.is_published) {
            await this.publishSecurityByType(securityTerm)
          }
          await this.publishFinancingRound(securityTerm.financing_round_id)
          await this.updateSecurityCetrificate(security)
          if (this.source === this.CONSTS.CAPTABLE_SOURCES_TYPES.V2) {
            await this.inviteCurrentHolder(security)
          }
          if (i === len) {
            await this.loadData()
            this.loading = false
            this.$store.dispatch('app/message', { text: 'Security issued successfully.' })
          }
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to issue security. Please try again later.',
          })
        }
      },

      async issueSecurityByType (securityId, securityTerm) {
        switch (securityTerm.type) {
          case this.CONSTS.SHARE_TERM_TYPES.COMMON:
          case this.CONSTS.SHARE_TERM_TYPES.PREFERRED:
            return captableService.issueShares(this.captableId, securityId)
          case this.CONSTS.SHARE_TERM_TYPES.WARRANT:
            return captableService.issueWarrants(this.captableId, securityId)
          case this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE:
            return captableService.issueConvertibles(this.captableId, securityId)
          case this.CONSTS.SHARE_TERM_TYPES.OPTION:
            return captableService.issueOptions(this.captableId, securityId)
        }
      },

      async publishSecurityByType (securityTerm) {
        switch (securityTerm.type) {
          case this.CONSTS.SHARE_TERM_TYPES.COMMON:
          case this.CONSTS.SHARE_TERM_TYPES.PREFERRED:
            await captableService.publishShareTerm(this.captableId, securityTerm.id)
            break
          case this.CONSTS.SHARE_TERM_TYPES.WARRANT:
            await captableService.publishWarrantTerm(this.captableId, securityTerm.id)
            break
          case this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE:
            await captableService.publishConvertibleTerm(this.captableId, securityTerm.id)
            break
          case this.CONSTS.SHARE_TERM_TYPES.OPTION:
            await captableService.publishEip(this.captableId, securityTerm.id)
            break
        }
      },

      async publishFinancingRound (financingRoundId) {
        if (financingRoundId) {
          const financingRound = this.financingRoundList.find((f) => f.id === financingRoundId)
          if (financingRound && !financingRound.is_published) {
            await captableService.publishFinancingRound(this.captableId, financingRoundId)
          }
        }
      },

      stopLoading () {
        this.loading = false
      },

      async deleteSecurity (item) {
        try {
          this.loading = true
          const resp = await this.deleteSecurityByType(item)
          if (resp && resp.status) {
            this.$store.dispatch('app/message', { text: 'Security deleted successfully.' })
            this.loadData(item.type)
            this.loading = false
          }
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to delete security. Please try again later.',
          })
        }
      },
      async issueSelectedSecurity (item, securityType) {
        try {
          this.loading = true
          if (!item.type && securityType) {
            item.type = securityType
          }
          await this.issueSelectedSecurityByType(item)
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to issue security. Please try again later.',
          })
        }
      },
      deleteSecurityByType (item) {
        switch (item.type) {
          case this.CONSTS.SHARE_TERM_TYPES.COMMON:
          case this.CONSTS.SHARE_TERM_TYPES.PREFERRED:
            return captableService.deleteShare(item.cap_table_id, item.issuedShareId)
          case this.CONSTS.SHARE_TERM_TYPES.WARRANT:
            return captableService.deleteWarrant(item.cap_table_id, item.issuedWarrantId)
          case this.CONSTS.OPTIONS_TYPE.ISO :
          case this.CONSTS.OPTIONS_TYPE.NSO :
          case this.CONSTS.OPTIONS_TYPE.ISONSO:
            return captableService.deleteOption(item.cap_table_id, item.issuedOptionId)
          case this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE:
            return captableService.deleteConvertible(item.cap_table_id, item.issuedConvertibleId)
        }
      },
      async issueSelectedSecurityByType (item) {
        switch (item.type) {
          case this.CONSTS.SHARE_TERM_TYPES.COMMON:
          case this.CONSTS.SHARE_TERM_TYPES.PREFERRED:
            return await this.issueSelectedShares([item])
          case this.CONSTS.SHARE_TERM_TYPES.WARRANT:
            return await this.issueSelectedWarrants([item])
          case this.CONSTS.OPTIONS_TYPE.ISO :
          case this.CONSTS.OPTIONS_TYPE.NSO :
          case this.CONSTS.OPTIONS_TYPE.ISONSO:
            return await this.issueSelectedOptions([item])
          case this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE:
            return await this.issueSelectedConvertibles([item])
        }
      },
      async reloadHandler (type) {
        await this.loadIssuerProfileList()
        switch (type) {
          case 'shared':
            this.loadDraftedShareList()
            break
          case 'warrant':
            this.loadDraftedWarrants()
            break
          case 'convertible':
            this.loadDraftedConvertibles()
            break
          case 'options':
            this.loadDraftedOptions()
            break
          default:
            break
        }
      },
      async updateSecurityCetrificate (item) {
        const shareDetails = this.getUpdatedDocTypes(item)
        await captableAdminService.updateSecurityCetrificate(this.captableId, shareDetails.termType, shareDetails.securityType, shareDetails.termId, shareDetails.securityId, shareDetails.registrationName, shareDetails.number)
      },
      getUpdatedDocTypes (item) {
        const shareDetails = {}
        switch (item.type) {
          case this.CONSTS.SHARE_TERM_TYPES.COMMON:
          case this.CONSTS.SHARE_TERM_TYPES.PREFERRED:
            shareDetails.termType = this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARE_TERMS
            shareDetails.securityType = this.CONSTS.NOTES_DOCUMENT_API_TYPE.SHARES
            shareDetails.termId = item.share_terms_id
            shareDetails.securityId = item.issuedShareId
            shareDetails.registrationName = item.registration_name
            shareDetails.number = item.number
            break
          case this.CONSTS.SHARE_TERM_TYPES.WARRANT:
            shareDetails.termType = this.CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANT_TERMS
            shareDetails.securityType = this.CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANTS
            shareDetails.termId = item.warrant_terms_id
            shareDetails.securityId = item.issuedWarrantId
            shareDetails.registrationName = item.registration_name
            shareDetails.number = item.number
            break
          case this.CONSTS.OPTIONS_TYPE.ISO :
          case this.CONSTS.OPTIONS_TYPE.NSO :
          case this.CONSTS.OPTIONS_TYPE.ISONSO:
            shareDetails.termType = this.CONSTS.NOTES_DOCUMENT_API_TYPE.EQUITY_INCENTIVE_PLANS
            shareDetails.securityType = this.CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS
            shareDetails.termId = item.equity_incentive_plan_id
            shareDetails.securityId = item.issuedOptionId
            shareDetails.registrationName = item.registration_name
            shareDetails.number = item.number
            break
          case this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE:
            shareDetails.termType = this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLE_TERMS
            shareDetails.securityType = this.CONSTS.NOTES_DOCUMENT_API_TYPE.CONVERTIBLES
            shareDetails.termId = item.convertible_terms_id
            shareDetails.securityId = item.issuedConvertibleId
            shareDetails.registrationName = item.registration_name
            shareDetails.number = item.amount
            break
        }
        return shareDetails
      },
      async inviteCurrentHolder (securityDetail) {
        const holderDetail = {
          name: securityDetail?.holderName,
          holder_id: securityDetail?.holder_id,
          username: securityDetail?.email,
        }
        await captableAdminService.inviteHolder(this.captableId, {
          ...holderDetail,
          is_security_issued: true,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-tabs.v-tabs--vertical {
    min-width: 225px !important;
    height: 100%;
  }
  ::v-deep .v-tabs-items {
    background-color: transparent !important;
  }
  .v-tabs {
    padding-top: 20px!important;
    .v-tab {
      height: 41px;
      font-size: 0.875rem !important;
      font-weight: 400;
      margin: 5px 0px;
      &:hover {
        color: var(--v-primary-base) !important;
        background-color: rgba(0, 159, 70, 0.1)!important;
      }
      &.v-tab--active {
        font-weight: 600;
        background-color: rgba(0, 159, 70, 0.1)!important;
      }
    }
  }
</style>
